

















































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { Listing, MapSettings } from 'client-website-ts-library/types';
import { ItemCyclerSlide, ItemCyclerReiszableImageSlide, ItemCyclerEmbeddableSlide } from '@/app_code/ItemCycler';
import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';
import { API, Logger, LogLevel } from 'client-website-ts-library/services';
import { EmbeddedContent, EmbeddedContentType } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';

import ItemCycler from '@/components/Cycler/ItemCycler.vue';
import InspectionCard from '@/components/InspectionCard.vue';
import ListingAgentProfile from '@/components/ListingAgentProfile.vue';
import Form from '@/components/Forms/Form.vue';
import { AnalyticsEvent, AnalyticsEventObject, AnalyticsEventType } from 'client-website-ts-library/services/Analytics';
import HomeLoanCalculator from '@/components/Calculators/HomeLoanCalculator.vue';
import LinkButton from '@/components/UI/LinkButton.vue';

const Map = () => import('client-website-ts-library/components/Map/Map.vue');

@Component({
  components: {
    ItemCycler,
    InspectionCard,
    Map,
    ListingAgentProfile,
    Form,
    HomeLoanCalculator,
  },
})
export default class ListingDetails extends Mixins(AsyncView) {
  private listing: Listing | null = null;

  private mapSettings?: MapSettings;

  private isMobile = false;

  private cyclerItems: ItemCyclerSlide[] = [];

  private cyclerBreakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
  ];

  private formData: FormConstructorData | null = null;

  private activeVirtualTour: EmbeddedContent | null = null;

  created() {
    API.Listings.Get(this.$route.params.id).then((listing) => {
      this.listing = listing;

      this.formData = new FormConstructorData([
        {
          Type: ContextItemType.Listing,
          Id: this.listing.Id,
        },
      ]);

      this.cyclerItems = listing.Images.map((img) => new ItemCyclerReiszableImageSlide(img));

      listing.EmbeddedContent.forEach((item) => {
        if (item.Type === EmbeddedContentType.Youtube || item.Type === EmbeddedContentType.Vimeo) {
          this.cyclerItems.splice(1, 0, new ItemCyclerEmbeddableSlide(item));
        }
      });

      if (listing.Address.DisplayAddress) {
        this.mapSettings = new MapSettings({
          Markers: [
            {
              Coordinate: listing.Address.Coordinates,
              Title: listing.Address.MicroAddress,
            },
          ],
        });
      }

      this.$emit('updateView');

      if (window.location.hash === '#virtualtour' && this.virtualTours != null && this.virtualTours.length > 0) {
        this.activeVirtualTour = this.virtualTours[0];
      }
    }).catch(() => {
      Logger.Log(LogLevel.Error, 'Failed to load listing');
    });
  }

  get isCurrent(): boolean {
    return this.listing!.Status === 'Current' || this.listing!.Status === 'Under Contract';
  }

  get isSale(): boolean {
    return this.listing!.MethodOfSale === 'Sale' || this.listing!.MethodOfSale === 'For Sale' || this.listing!.MethodOfSale === 'Auction';
  }

  get formattedAddressLine1(): string {
    const parts = [];

    if (this.listing === null) return '';

    if (this.listing.Address.UnitNumber) {
      parts.push(`${this.listing.Address.UnitNumber} / ${this.listing.Address.StreetNumber}`);
    } else {
      parts.push(this.listing.Address.StreetNumber);
    }

    parts.push(this.listing.Address.Address);

    return parts.join(' ');
  }

  get formattedAddressLine2(): string {
    if (this.listing === null) return '';

    return `${this.listing.Address.Suburb}, ${this.listing.Address.State} ${this.listing.Address.PostCode}`;
  }

  get formattedDateAvailable(): string {
    if (!this.listing!.DateAvailable) return 'now';

    const now = new Date();
    const dateAvailable = new Date(this.listing!.DateAvailable);

    if (dateAvailable <= now) return 'now';

    return `${dateAvailable.getDate()}/${(dateAvailable.getMonth() + 1)}/${dateAvailable.getFullYear().toString().substr(2, 2)}`;
  }

  get virtualTours(): EmbeddedContent[] | null {
    if (this.listing === null) return null;

    return this.listing.EmbeddedContent.filter((content) => content.Type === EmbeddedContentType.VirtualTour);
  }

  get sellingAgents(): string {
    if (this.isCurrent || this.listing === null) return '';

    if (this.listing.Agents.length === 1) return this.listing.Agents[0].FullName;

    const agents = [...this.listing.Agents];

    const last = agents.pop();

    return `${agents.map((a) => a.FullName).join(', ')} & ${last?.FullName}`;
  }

  pluralise(number: number, singular: string, plural?: string): string {
    // eslint-disable-next-line no-nested-ternary
    return `${number} ${(number === 1 ? singular : plural == null ? `${singular}s` : plural)}`;
  }

  public head(): RouteMeta {
    return {
      title: this.listing!.Address.DisplayAddress ? this.listing!.Address.MicroAddress : this.listing!.Header,
      description: this.listing!.Introduction,
      image: this.listing!.Images.length ? this.listing!.Images[0].Preview.Url : undefined,
    };
  }

  public getAnalyticsData(): AnalyticsEventObject | null {
    if (this.listing === null) return null;

    return AnalyticsEventObject.FromListing(this.listing);
  }

  handleVideoViewed() {
    this.triggerCustomAnalyticsEvent(new AnalyticsEvent(AnalyticsEventType.ListingVideoViewed, AnalyticsEventObject.FromListing(this.listing!)));
  }

  handleContactFormSubmitted() {
    this.triggerCustomAnalyticsEvent(new AnalyticsEvent(AnalyticsEventType.ContactFormSubmit, AnalyticsEventObject.FromListing(this.listing!)));
  }

  handleIREClicked() {
    API.Listings.GetInspectRealEstateData(this.listing!.Id).then((ireData) => {
      window.open(ireData.Url, '_blank');
    }).catch(() => {
      // eslint-disable-next-line no-alert
      alert('There was an error processing your request. Please try again later.');
    });
  }
}
